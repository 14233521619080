<template>
  <div class="session-bg">
    <v-dialog v-model="recoverPasswordDialog" max-width="400">
      <v-card>
        <v-card-title
          class="headline d-flex justify-space-between align-center"
        >
          <span>Recuperar Contraseña</span>
          <v-icon
            @click="closeRecoverPasswordDialog"
            class="close-icon"
            color="grey darken-1"
            style="cursor: pointer"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="recoverEmail"
            label="Correo Electrónico"
            :rules="emailRules"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="sendRecoverEmail">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      top
      :color="snackbar.color"
      multi-line
    >
      {{ snackbar.message }}
    </v-snackbar>

    <div class="signup4-container">
      <base-card>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pa-10">
            <img class="" src="@/assets/images/reportFE.png" />
          </v-col>
          <v-col cols="12" md="6" class="bg-gray-300">
            <v-form ref="form" class="mt-4">
              <div class="pa-10 mt-3">
                <v-text-field
                  v-model="dataLogin.email"
                  label="Correo"
                  :rules="emailRules"
                  required
                />
                <v-text-field
                  v-model="dataLogin.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-2"
                  label="Contraseña"
                  :counter="0"
                  :rules="[
                    () => !!dataLogin.password || 'Este campo es requerido',
                  ]"
                  required
                  @click:append="showPassword = !showPassword"
                />
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="sitekey"
                  @verify="onVerify"
                  @expired="onExpired"
                />
                <div style="text-align: center; margin-top: 5px">
                  <a
                    href="#"
                    @click.prevent="openRecoverPasswordDialog"
                    class="recover-password-link"
                  >
                    ¿Olvidaste tu contraseña?
                  </a>
                </div>
              </div>
              <v-alert
                v-show="errorAlert"
                type="error"
                dismissible
                dense
                outlined
              >
                Usuario o contraseña incorrecta
              </v-alert>
              <div style="text-align: -webkit-center">
                <v-btn
                  style="
                    background-color: rgb(204 33 40);
                    color: white !important;
                  "
                  v-show="showSignIn"
                  @click="singin"
                >
                  <span>Iniciar Sesión</span>
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </base-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";
import { environment } from "@/data/environment/";

export default {
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  name: "Login",
  metaInfo: {
    title: "Sign in",
  },
  data() {
    return {
      showPassword: false,
      showSignIn: false,
      dataLogin: [],
      errorAlert: false,
      loading: false,
      sitekey: "6LcGf0kqAAAAANa3zW1n_4aGVGtJYiIruIuMQ-W1",
      emailRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => /.+@.+\..+/.test(v) || "El formato de correo no es valido.",
      ],
      recoverPasswordDialog: false,
      recoverEmail: "",
      snackbar: {
        show: false,
        message: "",
        color: "error",
      },
    };
  },
  computed: {
    ...mapGetters(["loggedInUser", "error"]),
  },
  watch: {
    loggedInUser(val) {
      if (val && val.user && val.user.length > 0) {
        this.loading = true;
        setTimeout(() => {
          this.$router.push("/");
        }, 200);
      }
    },
    error(val) {
      if (val != null) {
        this.resetRecaptcha();

        this.snackbar.show = true;
        this.snackbar.message = "Usuario o contraseña incorrectos";
        this.snackbar.color = "error";

        this.errorAlert = false;

        setTimeout(() => {
          this.snackbar.show = false;
        }, 3000);

        this.clearerror();
      }
    },
    recoverPasswordDialog(val) {
    if (!val) {
      this.recoverEmail = '';
    }
  },
  },
  methods: {
    ...mapActions(["login", "clearerror", "generateTokenEmail"]),
    singin() {
      if (!this.dataLogin.email && !this.dataLogin.password) {
        this.$refs.form.validate();
        this.resetRecaptcha();
      } else {
        this.login({
          ...this.dataLogin,
        }).catch(() => {
          this.snackbar.show = true;
          this.snackbar.message =
            "Error en el inicio de sesión. Por favor intente nuevamente.";
          this.snackbar.color = "error";

          setTimeout(() => {
            this.snackbar.show = false;
          }, 3000);
        });
      }
    },
    hideAlertError() {
      this.clearerror();
    },
    onVerify: function (response) {
      this.showSignIn = true;
    },
    onExpired: function () {
      console.log("Expired");
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    openRecoverPasswordDialog() {
      this.recoverPasswordDialog = true;
    },
    closeRecoverPasswordDialog() {
      this.recoverPasswordDialog = false;
      this.recoverEmail = '';
    },
    async sendRecoverEmail() {
      if (this.recoverEmail) {
        try {
          const resolvedRoute = this.$router.resolve({
            name: "RememberPassword",
          });
          const fullUrl = `${environment.HIOPOSREPORTS}${resolvedRoute.route.fullPath}`;

          const response = await this.generateTokenEmail({
            email: this.recoverEmail,
            url: fullUrl,
          });

          if (response.error) {
            this.snackbar.message = response.Mensaje;
            this.snackbar.color = "error";
          } else {
            this.snackbar.message =
              "Correo de recuperación generado correctamente. Por favor revisa tu correo.";
            this.snackbar.color = "success";
          }
          this.snackbar.show = true;
        } catch (error) {
          this.snackbar.message =
            "El correo no esta registrado en la base de datos. Intente nuevamente.";
          this.snackbar.color = "error";
          this.snackbar.show = true;
        }

        setTimeout(() => {
          this.snackbar.show = false;
        }, 3000);
      }
    },
  },
};
</script>
<style lang="scss">
.session-bg {
  background-color: white;
  height: 100vh;
  align-items: center;
  place-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.signup4-header img {
  width: 200px;
}
.signup4-container img {
  max-height: 300px;
  max-width: 100%;
}
@media only screen and (min-width: 1024px) {
  .signup4-container {
    max-width: 800px;
  }
}
</style>
